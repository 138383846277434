<template>
    <component :is="component"
        :story="story">
        <v-storyblok-component v-for="blok in story?.content?.body || []"
            :key="blok.uuid || blok._uid"
            :blok="blok">
        </v-storyblok-component>
    </component>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import type { ISbStoryData } from 'storyblok-js-client';

const properties = defineProps<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    story?: ISbStoryData
}>();

const component = computed<unknown>(() => properties.story?.content?.component || 'div');
</script>
